<template>
  <div class="container-fluid inspection-global">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">
              {{ $t("ESTIMATE_VEHICLES.REQUEST_LOGS_LIST") }}
            </h3>
          </div>
          <div class="col-6 text-right">
            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_ANY_REQUEST_LOGS)"
              :objectType="'request-logs'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="list-table">
      <request-log-list-table
        @onViewRequestLog="openRequestLogViewModal"
        :key="renderKey * 200"
      />
    </div>

    <transition name="slide-right">
      <div
        v-if="isViewRequestLogModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewRequestLogModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content resize-full"
          v-resizable-modal="'REQUEST_LOG_VIEW'"
          @onCloseModal="closeRequestLogModal"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("ESTIMATE_VEHICLES.REQUEST_LOG") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-button
                v-if="$currentUserCan($permissions.PERM_DELETE_REQUEST_LOGS)"
                :title="$t('REQUESTS.CANCEL_REQUEST')"
                @click="deleteRequestLog(openRequestLog)"
                size="sm"
                type="warning"
                icon
              >
                <span class="btn-inner--icon">
                  <i class="fa fa-trash"></i>
                </span>
              </base-button>

              <notification-subscription
                v-if="openRequestLog"
                :objectType="'requests'"
                :objectId="openRequestLog.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />

              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="delete"
                  @click="deleteRequestLog(openRequestLog)"
                  v-if="$currentUserCan($permissions.PERM_DELETE_REQUEST_LOGS)"
                >
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>

              <button class="expand" id="expand-button">
                <i class="far fa-expand"></i>
              </button>

              <button class="close" @click="closeRequestLogModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-request-log-component :request-log-id="openRequestLog.id" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import swal from "sweetalert2";
import RequestLogListTable from "./partials/RequestLogListTable.vue";
import { QUERY_ACTIONS_VIEW } from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ViewRequestLogComponent from "./components/ViewRequestLogComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    RequestLogListTable,
    NotificationSubscription,
    ViewRequestLogComponent,
  },

  mixins: [requestErrorMixin],

  computed: {
    ...mapGetters("profile", { me: "me" }),
  },

  watch: {},

  created() {
    if (this.isViewRequestLogModalOpened && this.openRequestLog?.id) {
      this.get(this.openRequestLog?.id);
    }
  },

  data() {
    const requestLogId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewRequestLogModalOpened = false;
    let openRequestLog = null;
    if (requestLogId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewRequestLogModalOpened = true;
      }
      openRequestLog = { id: requestLogId };
    }
    return {
      isViewRequestLogModalOpened,
      openRequestLog,
      openRequestLogLoaded: false,
      renderKey: 1,
    };
  },

  methods: {
    closeRequestLogModal() {
      this.isViewRequestLogModalOpened = false;
      this.openRequestLog = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Request Logs",
          query: {},
        }).href
      );
    },
    openRequestLogViewModal(requestLog, reRender = false) {
      this.closeRequestLogModal();
      this.openRequestLog = requestLog;

      this.isViewRequestLogModalOpened = true;

      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Request Logs",
          query: { id: this.openRequestLog.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    async deleteRequestLog(requestLog) {
      const confirmation = await swal.fire({
        title: this.$t("ESTIMATE_VEHICLES.DELETE_THIS_REQUEST_LOG"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("requestLogs/destroy", requestLog.id);
          this.renderKey++;
          this.closeRequestLogModal();
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t("ESTIMATE_VEHICLES.REQUEST_LOG_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async get(id) {
      try {
        await this.$store.dispatch("requestLogs/get", id);
        this.openRequestLog = this.$store.getters["requestLogs/requestLog"];
        this.openRequestLogLoaded = true;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
