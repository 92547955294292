var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid inspection-global"},[_c('div',{staticClass:"page-wrapper"},[_c('div',{staticClass:"page-wrapper-header",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("ESTIMATE_VEHICLES.REQUEST_LOGS_LIST"))+" ")])]),_c('div',{staticClass:"col-6 text-right"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_REQUEST_LOGS))?_c('notification-subscription',{attrs:{"objectType":'request-logs',"events":{
              CREATE: _vm.$t('NOTIFICATIONS.EVENT_CREATE'),
              UPDATE: _vm.$t('NOTIFICATIONS.EVENT_UPDATE'),
              DELETE: _vm.$t('NOTIFICATIONS.EVENT_DELETE'),
            }}}):_vm._e()],1)])])]),_c('div',{staticClass:"list-table"},[_c('request-log-list-table',{key:_vm.renderKey * 200,on:{"onViewRequestLog":_vm.openRequestLogViewModal}})],1),_c('transition',{attrs:{"name":"slide-right"}},[(_vm.isViewRequestLogModalOpened)?_c('div',{key:_vm.renderKey * 200,staticClass:"resizable-wrapper",class:[_vm.isViewRequestLogModalOpened ? 'show' : 'hide']},[_c('div',{directives:[{name:"resizable-modal",rawName:"v-resizable-modal",value:('REQUEST_LOG_VIEW'),expression:"'REQUEST_LOG_VIEW'"}],staticClass:"resizable-wrapper-content resize-full",on:{"onCloseModal":_vm.closeRequestLogModal}},[_c('div',{staticClass:"resizable-wrapper-content-header"},[_c('div',{staticClass:"resizable-wrapper-content-header-left"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t("ESTIMATE_VEHICLES.REQUEST_LOG"))+" ")])]),_c('div',{staticClass:"resizable-wrapper-content-header-right"},[(_vm.$currentUserCan(_vm.$permissions.PERM_DELETE_REQUEST_LOGS))?_c('base-button',{attrs:{"title":_vm.$t('REQUESTS.CANCEL_REQUEST'),"size":"sm","type":"warning","icon":""},on:{"click":function($event){return _vm.deleteRequestLog(_vm.openRequestLog)}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fa fa-trash"})])]):_vm._e(),(_vm.openRequestLog)?_c('notification-subscription',{attrs:{"objectType":'requests',"objectId":_vm.openRequestLog.id,"events":{
                UPDATE: _vm.$t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: _vm.$t('NOTIFICATIONS.EVENT_DELETE'),
              }}}):_vm._e(),_c('base-dropdown',{attrs:{"title-classes":"btn dropdown-button","menu-on-right":"","has-toggle":false}},[_c('template',{slot:"title"},[_c('i',{staticClass:"fas fa-ellipsis-v"})]),(_vm.$currentUserCan(_vm.$permissions.PERM_DELETE_REQUEST_LOGS))?_c('button',{staticClass:"delete",on:{"click":function($event){return _vm.deleteRequestLog(_vm.openRequestLog)}}},[_c('i',{staticClass:"fal fa-trash-alt"}),_c('span',[_vm._v(_vm._s(_vm.$t("COMMON.DELETE")))])]):_vm._e()],2),_c('button',{staticClass:"expand",attrs:{"id":"expand-button"}},[_c('i',{staticClass:"far fa-expand"})]),_c('button',{staticClass:"close",on:{"click":_vm.closeRequestLogModal}},[_c('i',{staticClass:"fal fa-times"})])],1)]),_c('div',{staticClass:"resizable-wrapper-content-body"},[_c('view-request-log-component',{attrs:{"request-log-id":_vm.openRequestLog.id}})],1)])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }