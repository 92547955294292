<template>
  <div class="list-table-inner">
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-header">
        <div class="advanced-search-item pagination-select">
          <el-select
            class="select-primary pagination-select"
            v-model="pagination.perPage"
            :placeholder="$t('COMMON.PER_PAGE')"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="advanced-search-item date">
          <date-range-selector
            @onDateRangeChanged="
              (dates) => {
                selectedDateRange = dates;
              }
            "
          />
        </div>
        <div
          v-if="
            !filterOrganization &&
            $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
          "
          class="advanced-search-item"
        >
          <organization-selector
            :filterable="true"
            @organizationChanged="
              (organizationId) => (selectedOrganization = organizationId)
            "
          />
        </div>
        <div
          class="advanced-search-item"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
        >
          <user-selector
            :filterable="true"
            :filterOrganization="filterOrganization ?? selectedOrganization"
            @userChanged="(userId) => (selectedUser = userId)"
          />
        </div>
        <div
          class="advanced-search-item"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
        >
          <domain-selector
            :filterable="true"
            :filterOrganization="filterOrganization ?? selectedOrganization"
            @domainChanged="(domainId) => (selectedDomain = domainId)"
          />
        </div>
        <div class="advanced-search-item">
          <el-select
            class="select-primary"
            v-model="selectedStatusCode"
            :placeholder="$t('COMMON.STATUS_CODE')"
          >
            <el-option :value="null" :label="$t('COMMON.STATUS_CODE')">
            </el-option>
            <el-option
              class="select-primary"
              v-for="item in estimateVehicleRequestLogsStatusCodeOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div
          class="advanced-search-item"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
        >
          <base-input
            v-model="query"
            type="search"
            prepend-icon="fas fa-search"
            :placeholder="$t('COMMON.IP_ADDRESS')"
            clearable
          />
        </div>
      </div>
    </div>
    <div class="list-table-inner-body">
      <h3>
        {{ $tc("ESTIMATE_VEHICLES.TOTAL_REQUEST_LOG", total, { total }) }}
      </h3>

      <div class="list-table-inner-body-inner">
        <el-table
          class="align-items-center table-flush"
          header-row-class-name="thead-light"
          :data="requestLogs"
          @sort-change="sortChange"
          row-class-name="cursor-pointer"
          @row-click="
            (row) => {
              viewRequestLog(row);
            }
          "
        >
          <div class="table-loading" slot="empty" v-if="loading">
            <img src="/img/loading.gif" />
          </div>
          <el-table-column
            :label="$t('COMMON.USER')"
            prop="price"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              <template v-if="!!row.user">
                <object-link
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
                  :object="row.user"
                />
                <span v-else>{{ $objectDenomination(row.user) }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('COMMON.IP_ADDRESS')"
            prop="ip_address"
            sortable="custom"
            min-width="220"
          />
          <el-table-column
            :label="$t('COMMON.DOMAIN')"
            prop="domain"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              <span>
                {{ row.domain?.name ?? "-" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('COMMON.STATUS_CODE')"
            prop="status_code"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              <request-log-status-badge :status="row.status_code" />
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('COMMON.CREATED_AT')"
            prop="created_at"
            sortable="custom"
            min-width="220"
          />
          <el-table-column
            :fixed="$listActionsButtonsPosition()"
            min-width="120"
          >
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip
                :content="$t('COMMON.VIEW')"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_VIEW_REQUEST_LOGS)"
              >
                <a
                  type="text"
                  @click="viewRequestLog(row)"
                  class="table-action"
                  data-toggle="tooltip"
                >
                  <i class="fal fa-expand-alt"></i>
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div slot="footer" class="list-table-inner-footer">
      <p class="card-category">
        {{
          $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
            from: total ? from + 1 : 0,
            to: to,
            of: total,
          })
        }}

        <span v-if="selectedRows.length">
          &nbsp; &nbsp;
          {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
        </span>
      </p>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { estimateVehicleRequestLogsStatusCodeOptions } from "@/constants/estimateVehicles";
import { BasePagination } from "@/components";
import DateRangeSelector from "@/components/DateRangeSelector.vue";
import UserSelector from "@/components/UserSelector.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import DomainSelector from "@/components/DomainSelector.vue";
import RequestLogStatusBadge from "./RequestLogStatusBadge.vue";
import "flatpickr/dist/flatpickr.css";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  name: "request-list-table",

  components: {
    BasePagination,
    DateRangeSelector,
    UserSelector,
    OrganizationSelector,
    DomainSelector,
    RequestLogStatusBadge,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },
  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "organization id",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-updated_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      requestLogs: [],
      loading: true,
      showAdvancedFilter: false,
      selectedDateRange: null,
      selectedOrganization: null,
      selectedUser: null,
      selectedDomain: null,
      selectedStatusCode: null,
      estimateVehicleRequestLogsStatusCodeOptions,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterStatus: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedStatus: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedDateRange: {
      handler: "getListDebounced",
      immediate: false,
      deep: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedUser: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedDomain: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedStatusCode: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "user,domain",
        };
        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }
        if (this.selectedDomain) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              domain: this.selectedDomain,
            },
          };
        }
        if (this.selectedUser) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              user: this.selectedUser,
            },
          };
        }
        if (this.selectedDateRange) {
          if (this.selectedDateRange.length == 2) {
            params = {
              ...params,
              filter: {
                ...params.filter,
                created_at: [
                  this.selectedDateRange[0] + " 00:00:00",
                  this.selectedDateRange[1] + " 23:59:59",
                ],
              },
            };
          }
        }
        if (this.selectedStatusCode) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              status: this.selectedStatusCode,
            },
          };
        }

        await this.$store.dispatch("requestLogs/list", params);
        this.requestLogs = this.$store.getters["requestLogs/list"];
        this.total = this.$store.getters["requestLogs/listTotal"];
        this.loading = false;
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    viewRequestLog(request) {
      this.$emit("onViewRequestLog", request);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
